import axios from 'axios'

import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import BigNumber from "bignumber.js"

import { DAO, ERC20, IERC20 } from '../abi'

import { aspisConfiguration } from '../helpers/daoApiConfig'
import { getNetworkConfig } from '../const/networks'

const Web3EthAbi = require('web3-eth-abi');

export enum ReturnCode {
  Success = 1,
  EstimateError = 2,
  UnknownError = 3
}

export class DaoAPI {
  static async getAllDaos(page: number, limit: number, query: any): Promise<any> {
    if (page && limit) {
      try {

        let params: {
          params: {
            page: number;
            limit: number;
            query?: any;
          };
        } = {
          params: {
            page,
            limit,
          }
        };

        if (query) {
          params.params.query = query;
        }

        const response = await axios.get('/funds/list', params);
        const data = response.data;

        return {
          page: data.page,
          limit: data.limit,
          total: data.total,
          totalPages: data.totalPages,
          records: data.records,
          rates: data.rates,
          status: response.status
        };
      } catch (error) {
        console.error(error);
      }
    }

  }

  static async getDaosFromGraph(page: number, limit: number, chainId: any, query: any): Promise<any> {
    if (page && limit && chainId) {
      try {

        let params: {
          params: {
            page: number;
            limit: number;
            chainId: any;
            query?: any;
          };
        } = {
          params: {
            page,
            limit,
            chainId
          }
        };

        if (query) {
          params.params.query = query;
        }

        const response = await axios.get('/funds/list', params);
        const data = response.data;
        return {
          page: data.page,
          limit: data.limit,
          total: data.total,
          totalPages: data.totalPages,
          records: data.records,
          rates: data.rates,
          status: response.status
        };
      } catch (error) {
        console.error(error);
      }
    }
  }

  static async getStats(): Promise<any> {
    const { data } = await axios.get('/funds/stats')
    return data.data
  }


  static async getDaoById(id: string, chainId: any): Promise<any> {
    const { data } = await axios.get(`/fund/${id}`, { params: { chainId } })
    return data
  }

  static async getConfiguration(address: string, library: any): Promise<any> {
    try {

      const configuration = await aspisConfiguration(address, library)
      const data = await configuration.methods.getConfiguration().call()

      return {
        maxCap: data['0'],
        minDeposit: data['1'],
        maxDeposit: data['2'],
        startTime: data['3'],
        finishTime: data['4'],
        withdrawlWindow: data['5'],
        freezePeriod: data['6'],
        lockLimit: data['7'],
        spendingLimit: data['8'],
        initialPrice: data['9'],
        canChangeManager: data['10'],
        isPublicFund: data['11'],
        isDirectTransferAllowed: data['12'],
        configAddress: address,
      }

    } catch (e) {
      console.log('Error message:', e)
    }
  }

  static async getLpPrices(fundId: string) {
    try {
      const response = await axios.get(`/lpprices/${fundId}`); // TODO: startDate, endDate
      const data = response.data;
      return data;
    } catch (e) {
      console.log('Error message:', e)
    }
  }

  static async getWhitelistedUsers(address: string, library: any): Promise<any> {
    try {
      const configuration = await aspisConfiguration(address, library)
      return await configuration.methods.getWhiteListUsers().call()
    } catch (e) {
      console.log('Error message:', e)
    }
  }

  static async getTrustedTransfers(address: string, library: any): Promise<any> {
    try {
      const configuration = await aspisConfiguration(address, library)
      return await configuration.methods.getTrustedProtocols().call()
    } catch (e) {
      console.log('Error message:', e)
    }
  }

  static async getDefiProtocols(protocols: any, address: string, library: any): Promise<any> {
    try {
      const SELECTED_PROTOOCLS: any = [] // these are the selected ones
      const configuration = await aspisConfiguration(address, library)

      for (const protocol of protocols) {
        // @ts-ignore
        const isExisting = await configuration.methods.supportsProtocol(protocol.protocolAddress).call()
        if (isExisting) {
          SELECTED_PROTOOCLS.push(protocol.protocolAddress.toLowerCase())
        }
      }

      return SELECTED_PROTOOCLS
    } catch (e) {
      console.log('Error message:', e)
    }
  }

  static async getDaoFees(address: string, library: any): Promise<any> {
    try {
      const configuration = await aspisConfiguration(address, library)
      const data = await configuration.methods.getFees().call()

      return {
        entranceFee: data['0'],
        performanceFee: data['1'],
        fundManagementFee: data['2'],
        rageQuitFee: data['3'],
      }
    } catch (e) {
      console.log('Error message:', e)
    }
  }

  static async getManager(address: string, library: any, useLibrary = false): Promise<any> {
    try {

      let web3

      if (Web3.givenProvider && !useLibrary) {
        web3 = new Web3(Web3.givenProvider)
      } else {
        web3 = new Web3(library.provider)
      }

      const DaoInstance = new web3.eth.Contract(
        DAO as AbiItem[],
        address
      )
      return await DaoInstance.methods.getManager().call()
    } catch (err) {
      console.log('Error while fetching trusted forwarder:', err);
    }
  }

  static async getDepositTokens(address: string, library: any): Promise<any> {
    try {
      const configuration = await aspisConfiguration(address, library)
      return await configuration.methods.getDepositTokens().call()
    } catch (err) {
      console.log('Error while fetching trusted forwarder:', err);
    }
  }

  static async getTradingTokens(address: string, library: any): Promise<any> {
    try {
      const configuration = await aspisConfiguration(address, library)
      return await configuration.methods.getTradingTokens().call()
    } catch (err) {
      console.log('Error while fetching trusted forwarder:', err);
    }
  }

  static async getVoting(address: string, library: any): Promise<any> {
    try {
      let web3
      if (Web3.givenProvider) {
        web3 = new Web3(Web3.givenProvider)
      } else {
        web3 = new Web3(library.provider)
      }

      const daoInstance = new web3.eth.Contract(
        DAO as AbiItem[],
        address
      )
      return await daoInstance.methods.getVotingAddress().call()
    } catch (e) {
      console.log('Error message:', e)
    }
  }

  static async createDao(daoData: any, account: string | null | undefined, DaoRegistry: any, chainId: number): Promise<any> {
    try {
      // Проверка входных данных
      if (!account || !DaoRegistry) {
        throw new Error('Missing required parameters');
      }

      // Подготовка конфигурации
      const daoConfig = {
        name: daoData.name,
        symbol: daoData.symbol,
        poolConfig: [
          daoData.maxCap,
          daoData.minDeposit,
          daoData.maxDeposit,
          daoData.startTime,
          daoData.finishTime,
          daoData.withdrawlWindow * 24,
          daoData.freezePeriod * 24,
          daoData.lockLimit,
          0, // Not needed for v1
          daoData.initialPrice * Math.pow(10, 4),
          daoData.canChangeManager,
          daoData.entranceFee * 100,
          daoData.fundManagementFee * 100,
          daoData.performanceFee * 100,
          daoData.rageQuitFee * 100,
          daoData.isDirectTransferAllowed
        ],
        metadata: []
      }

      const votingConfig = [
        `${new BigNumber(daoData.quorum).multipliedBy('1e16')}`,
        `${new BigNumber(daoData.minimumApproval).multipliedBy('1e16')}`,
        `${new BigNumber(daoData.votingDuration).multipliedBy(60).multipliedBy(60)}`,
        `${new BigNumber(daoData.minLPTokenShare).multipliedBy('1e16')}`,
      ]

      // Создаем транзакцию
      const transaction = DaoRegistry.methods.newERC20AspisPoolDAO(
        daoConfig,
        votingConfig,
        [
          daoData.whiteListedAddresses, // whitelistVoters => Lp agreement,
          daoData.defiProtocols,
          daoData.supportedDepositTokens,
          daoData.supportedTradingTokens
        ]
      )

      // Оцениваем газ перед отправкой
      let gasEstimate;
      try {
        gasEstimate = await transaction.estimateGas({
          from: account,
        });
      } catch (error: any) {
        console.error('Gas estimation failed:', error);

        // Проверка на ошибку, связанную с дублированием имени DAO
        if (error.data && error.data.includes('0xc30a5ef2')) {
          throw new Error('DAO with this name is already taken');
        }

        throw new Error(`Failed to estimate gas: ${error.message}`);
      }

      // Отправляем транзакцию
      try {
        const dao = await transaction.send({
          from: account,
          gas: Math.floor(gasEstimate * 1.2), // добавляем 20% к оценке газа
          maxPriorityFeePerGas: null,
          maxFeePerGas: null
        });

        console.log('DAO creation response:', dao);
        console.log('Events:', dao.events);

        if (!dao.events || !dao.events.DAOCreated) {
          throw new Error('DAOCreated event not found');
        }

        // Подготовка данных для отправки на сервер
        const daoAddress = dao.events.DAOCreated.returnValues[0];
        const tokenAddress = dao.events.DAOCreated.returnValues[2]; // получаем токен из returnValues[2]

        if (!daoAddress || !tokenAddress) {
          console.error('Event data:', dao.events.DAOCreated);
          throw new Error('Failed to get DAO or token address from event');
        }

        const formData = new FormData();
        const links = JSON.stringify({
          twitter: daoData.twitterLink,
          medium: daoData.mediumLink,
          telegram: daoData.telegramLink,
          website: daoData.websiteLink,
          docs: daoData.docsLink,
        });

        formData.append('name', daoData.name);
        formData.append('address', daoAddress.toLowerCase());
        formData.append('isWhitelisted', 'true');
        formData.append('description', daoData.description);
        formData.append('logoImg', daoData.selectedImg);
        formData.append('links', links);
        formData.append('chainId', String(chainId));
        formData.append('token', tokenAddress.toLowerCase());
        formData.append('manager', account.toLowerCase());

        // Отправка данных на сервер
        try {
          const response = await axios({
            method: 'post',
            url: '/funds/create',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          return response;
        } catch (serverError: any) {
          console.error('Server error:', serverError);
          throw new Error(`Failed to save DAO data: ${serverError.message}`);
        }

      } catch (txError: any) {
        console.error('Transaction error:', txError);
        if (txError.code === -32603) {
          throw new Error('Transaction failed. Please check your wallet connection and try again.');
        }
        // Добавляем более подробную информацию об ошибке
        throw new Error(`Transaction failed: ${txError.message || 'Unknown error'}`);
      }

    } catch (error: any) {
      console.error('CreateDao error:', error);
      return Promise.reject(error);
    }
  }

  static async getAllowance(token: string, account: string | null | undefined, daoAddress: string, library: any): Promise<any> {
    try {

      let web3
      if (Web3.givenProvider) {
        web3 = new Web3(Web3.givenProvider)
      } else {
        web3 = new Web3(library.provider)
      }

      if (token !== '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE') {
        const ERC20 = new web3.eth.Contract(IERC20 as AbiItem[], token)
        return await ERC20.methods.allowance(account, daoAddress).call()
      }
    } catch (e) {
      console.log('Error message:', e)
    }
  }

  static async approveDeposit(daoAddress: string, token: string, account: string | null | undefined, depositAmount: number, library: any): Promise<any> {

    let web3
    if (Web3.givenProvider) {
      web3 = new Web3(Web3.givenProvider)
    } else {
      web3 = new Web3(library.provider)
    }

    if (token === '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE') {
      return Promise.resolve(true)
    }
    const ERC20 = new web3.eth.Contract(IERC20 as AbiItem[], token)
    const { status } = await ERC20.methods
      .approve(daoAddress, `${depositAmount}`)
      .send({
        from: account,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      })
    return status
  }

  static async deposit(daoAddress: string, token: string, decimals: any, amount: number, account: string | null | undefined, library: any): Promise<any> {
    try {

      let web3
      if (Web3.givenProvider) {
        web3 = new Web3(Web3.givenProvider)
      } else {
        web3 = new Web3(library.provider)
      }

      const daoInstance = new web3.eth.Contract(DAO as AbiItem[], daoAddress)
      if (token === '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE') {
        return await daoInstance.methods
          .deposit(token, new BigNumber(amount).multipliedBy(`1e${decimals}`).toString())
          .send({
            from: account,
            value: new BigNumber(amount).multipliedBy(`1e${decimals}`),
            maxPriorityFeePerGas: null,
            maxFeePerGas: null,
          })
      }
      return await daoInstance.methods
        .deposit(token, (new BigNumber(amount).multipliedBy(`1e${decimals}`)).toFixed().toString())
        .send({
          from: account,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        })
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async burn(daoId, account, library: any): Promise<any> {
    try {

      let web3
      if (Web3.givenProvider) {
        web3 = new Web3(Web3.givenProvider)
      } else {
        web3 = new Web3(library.provider)
      }

      const daoInstance = new web3.eth.Contract(DAO as AbiItem[], daoId)
      const burn = await daoInstance.methods
        .withdraw(account)
        .send({
          from: account,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        })

      return burn
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async claimManagerFees(daoId, account, library: any): Promise<any> {
    try {

      let web3
      if (Web3.givenProvider) {
        web3 = new Web3(Web3.givenProvider)
      } else {
        web3 = new Web3(library.provider)
      }

      const daoInstance = new web3.eth.Contract(DAO as AbiItem[], daoId)
      const claim = await daoInstance.methods.withdrawCommission().send({
        from: account,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      })

      return claim
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static async getTokenBalance(token: string, account: any, decimals: any, library: any): Promise<any> {
    try {

      let web3
      if (Web3.givenProvider) {
        web3 = new Web3(Web3.givenProvider)
      } else {
        web3 = new Web3(library.provider)
      }

      if (token === '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE') {
        const balance = await web3.eth.getBalance(account)
        return web3.utils.fromWei(balance, "ether")
      }
      const ERC20Configuration = new web3.eth.Contract(
        ERC20 as AbiItem[],
        token,
        { from: account }
      )
      const balance = await ERC20Configuration.methods.balanceOf(account).call()
      const toAmount = new BigNumber(balance).dividedBy(`1e${decimals}`).toString()
      return toAmount
      // return balance / Math.pow(10, decimals)
    } catch (err) {
      console.log('Error fetching token balance:', err);
    }
  }

  static async getTokenMetaData(token: string, account: any, chainId: any, library: any): Promise<any> {
    try {

      let web3
      if (Web3.givenProvider) {
        web3 = new Web3(Web3.givenProvider)
      } else {
        web3 = new Web3(library.provider)
      }

      if (token === '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE') {
        const network = getNetworkConfig(chainId);
        const symbol = chainId ? network.params.nativeCurrency.symbol : 'ETH';
        return {
          symbol: symbol,
          address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
          decimals: 18
        }
      }

      const ERC20Instance = new web3.eth.Contract(
        ERC20 as AbiItem[],
        token,
        { from: account }
      )
      const symbol = await ERC20Instance.methods.symbol().call()
      const decimals = await ERC20Instance.methods.decimals().call()

      return {
        symbol,
        address: token,
        decimals
      }
    } catch (err) {
      console.log('Error fetching token metadata:', err);
    }
  }

  static async getTotalSupply(token, library: any, useLibrary = false): Promise<any> {

    let web3
    if (Web3.givenProvider && !useLibrary) {
      web3 = new Web3(Web3.givenProvider)
    } else {
      web3 = new Web3(library.provider)
    }

    const ERC20 = new web3.eth.Contract(IERC20 as AbiItem[], token)
    try {
      return await ERC20.methods.totalSupply().call()
    } catch (err) {
      console.log('Error while fetching total supply:', err);
    }
  }

  static async agentTransfer(token: string, recipient: string, amount: any, library: any) {
    try {

      let web3
      if (Web3.givenProvider) {
        web3 = new Web3(Web3.givenProvider)
      } else {
        web3 = new Web3(library.provider)
      }

      const DaoInstance = new web3.eth.Contract(
        ERC20 as AbiItem[],
        token
      )
      const action = {
        to: token,
        data: DaoInstance.methods.transfer(recipient, amount).encodeABI()
      }
      return action
    } catch (err) {
      return Promise.reject(err)
    }
  }

  static async agentApprove(token: string, spender: string, amount: any, library: any) {
    try {

      let web3
      if (Web3.givenProvider) {
        web3 = new Web3(Web3.givenProvider)
      } else {
        web3 = new Web3(library.provider)
      }

      const DaoInstance = new web3.eth.Contract(
        ERC20 as AbiItem[],
        token
      )
      const action = {
        to: token,
        data: DaoInstance.methods.approve(spender, amount).encodeABI()
      }
      return action
    } catch (err) {
      return Promise.reject(err)
    }
  }

  static async encodeApprovalCall(actor: string, pool: string, data: any, library: any): Promise<[ReturnCode, any]> {
    try {
      let web3: Web3
      if (Web3.givenProvider) {
        web3 = new Web3(Web3.givenProvider)
      } else {
        web3 = new Web3(library.provider)
      }

      const jsonInterface = {
        "inputs": [
          {
            "internalType": "address",
            "name": "_token",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "_spender",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
          }
        ],
        "name": "approveTokenTransfer",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      };
      const encodedData = Web3EthAbi.encodeFunctionCall(jsonInterface, [data[0], data[1], data[2]]);

      let gas: number;
      try {
        gas = await web3.eth.estimateGas({ from: actor, to: pool, data: encodedData })
      } catch (error) {
        return [ReturnCode.EstimateError, error]
      }

      const result = await web3.eth.sendTransaction({
        from: actor,
        to: pool,
        data: encodedData,
        gas: (gas + 30_000).toString(),
        maxPriorityFeePerGas: null!,
        maxFeePerGas: null!
      })
      return [ReturnCode.Success, result]
    } catch (error) {
      return [ReturnCode.UnknownError, error]
    }
  }

  static async encodeDAOCall(actor: string, pool: string, data: any, library: any): Promise<[ReturnCode, any]> {
    try {
      let web3: Web3
      if (Web3.givenProvider) {
        web3 = new Web3(Web3.givenProvider)
      } else {
        web3 = new Web3(library.provider)
      }

      const jsonInterface = {
        "inputs": [
          {
            "internalType": "address",
            "name": "_target",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "_ethValue",
            "type": "uint256"
          },
          {
            "internalType": "bytes",
            "name": "_data",
            "type": "bytes"
          }
        ],
        "name": "execute",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      };

      const encodedData = Web3EthAbi.encodeFunctionCall(jsonInterface, [data[0], data[1], data[2]]);
      let gas: number;

      try {
        gas = await web3.eth.estimateGas({ from: actor, to: pool, data: encodedData })
      } catch (error) {
        return [ReturnCode.EstimateError, error]
      }

      const result = await web3.eth.sendTransaction({
        from: actor,
        to: pool,
        data: encodedData,
        gas: (gas + 100000).toString(),
        maxPriorityFeePerGas: null!,
        maxFeePerGas: null!
      })
      return [ReturnCode.Success, result]
    } catch (error) {
      return [ReturnCode.UnknownError, error]
    }
  }
}

